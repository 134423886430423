import { useState, useCallback } from 'react';
export default function usePopover() {
    var _a = useState(null), open = _a[0], setOpen = _a[1];
    var onOpen = useCallback(function (event) {
        setOpen(event.currentTarget);
    }, []);
    var onClose = useCallback(function () {
        setOpen(null);
    }, []);
    return {
        open: open,
        onOpen: onOpen,
        onClose: onClose,
        setOpen: setOpen,
    };
}
